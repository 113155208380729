// router/index.js
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { requireAuth } from "./middleware";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // ... остальные маршруты ...
  // {
  //   path: "/crm",
  //   name: "CrmView",
  //   component: () => import("../views/CrmView.vue"),
  //   beforeEnter: requireAuth,
  // },
  {
    path: "/marketplatforma",
    name: "MarketPlatformaView",
    component: () => import("../views/MarketPlatformaView.vue"),
    beforeEnter: requireAuth,
  },

  {
    path: "/marketplatforma/project/:id",
    name: "MarketPlatformaProjectView",
    component: () => import("../views/MarketPlatformaProjectView.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/marketplatforma/report/:id",
    name: "MarketPlatformaProjectReportView",
    component: () => import("../views/MarketPlatformaProjectReportView.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/marketplatforma/posts/:id",
    name: "MarketPlatformaProjectPostsView",
    component: () => import("../views/MarketPlatformaProjectPostsView.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/marketplatforma/groups/:id",
    name: "MarketPlatformaProjectGroupsView",
    component: () => import("../views/MarketPlatformaProjectGroupsView.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/mp-groups/",
    name: "MarketPlatformaGroupsStataView",
    component: () => import("../views/MarketPlatformaGroupsStataView.vue"),
    beforeEnter: requireAuth,
  },

  {
    path: "/mp-permission/",
    name: "MarketPlatformaPermissionView",
    component: () => import("../views/MarketPlatformaPermissionView.vue"),
    beforeEnter: requireAuth,
  },

  // ... остальные маршруты ...
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
