<template>
  <header class="app-header">
    <div class="app-header__inner">
      <div class="app-logo">
        <img
          width="60"
          height="60"
          alt="Умные посевы"
          src="../assets/logo.png"
        />
        <div class="app-logo__text">
          <!-- <p>Posevun.com</p> -->
          <p>Posevonline.com</p>
          <p>Умные посевы в социальных сетях</p>
        </div>
      </div>
      <nav class="app-nav">
        <ul>
          <li><router-link to="/">Главная</router-link></li>
          <li>
            <router-link to="/marketplatforma">Маркет-платформа</router-link>
          </li>
          <!-- <li><router-link to="/crm">Ручные посевы</router-link></li> -->
        </ul>
      </nav>
      <div class="app-header__user-info">
        <login-button />
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import LoginButton from "@/components/LoginButton.vue";
export default {
  name: "AppHeader",
  components: {
    LoginButton,
  },
  computed: {
    ...mapState(["isAuthenticated"]),
  },
};
</script>

<style lang="scss" scoped>
.app-header {
  width: 100%;
  &__inner {
    width: 100%;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.app-logo {
  display: flex;
  align-items: center;
  img {
    margin-right: 15px;
  }
  &__text {
    p {
      margin: 2px 0;
      text-align: left;
      &:first-child {
        font-size: 16px;
        font-weight: bold;
      }
      &:last-child {
        font-size: 12px;
      }
    }
  }
}

.app-nav {
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      margin: 0 15px;
      a {
        text-decoration: none;
      }
    }
  }
}
</style>
