<template>
  <div class="home">
    <p>Сервис для анализа и подбора групп на Маркет-платформе ВК</p>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  // components: {
  //   HelloWorld,
  // },
};
</script>
